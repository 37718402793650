import { createSlice } from '@reduxjs/toolkit'

import { getSessionStorageItem, removeItemInSessionStorage, setItemInSessionStorage } from '../../utils/functions'

const tournamentsSlice = createSlice({
	name: 'tournaments',
	initialState: {
		scroll: getSessionStorageItem('tournamentsScroll')
	},
	reducers: {
		setTournamentsScroll(state, action) {
			if (action.payload) {
				setItemInSessionStorage('tournamentsScroll', action.payload)
			} else {
				removeItemInSessionStorage('tournamentsScroll')
			}

			state.scroll = action.payload
		}
	}
})

export const {
	setTournamentsScroll
} = tournamentsSlice.actions
export default tournamentsSlice.reducer