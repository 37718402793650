import React, { useState, useEffect, memo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import './TableMatches.scss'
import { selectAuth, selectInitialStage, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import { NODE_TYPE_IN, NODE_TYPE_MIDDLE, TYPE_DOUBLES } from '../../utils/constants'
import { generateGroupOrLevelsName } from '../../utils/functions'
import { ReactComponent as Ball } from '../../icons/tennis_ball.svg'
import { getParameters } from '../../utils/functions'
import { setMatchData, setMatchSession } from '../../redux/matchService/matchSlice'
import NoGroups from '../../reusableComponents/NoGroups/NoGroups'
import { addFirstSet } from '../../views/Match/externalFunctions'
import EmptyParticipant from '../../reusableComponents/EmptyParticipant/EmptyParticipant'

function TableMatches() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const { authorized, guestCode } = useSelector(selectAuth)
	const { players, doubles, type } = useSelector(selectTournament)
	const { tournamentParams } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const { stage: options = {} } = useSelector(selectTournamentOptions)
	const initialStage = useSelector(selectInitialStage)

	const [tableMatches, setTableMatches] = useState([])
	const [dropTableIndex, setDropTableIndex] = useState('')

	// updatedMatch для таблицы Матчи
	useEffect(() => {
		if (initialStage && Object.keys(initialStage).length !== 0) {
			const tableMatchesArray = initialStage?.levels?.map((level, levelIndex) => {
				return level?.groups?.map((group, groupIndex) => {
					const matchesArray = group?.matches?.map(match => {
						if (doubles === null) {
							// Одиночный
							const playerSide1 = group.nodes.find((item) => {
								return match.side1_uid === item.uid && (item.type === NODE_TYPE_IN || item.type === NODE_TYPE_MIDDLE)
							})
							const matchSide1 = players.find((item) => {
								return playerSide1?.player_uid === item.uid
							})

							const playerSide2 = group.nodes.find((item) => {
								return match.side2_uid === item.uid && (item.type === NODE_TYPE_IN || item.type === NODE_TYPE_MIDDLE)
							})
							const matchSide2 = players.find((item) => {
								return playerSide2?.player_uid === item.uid
							})

							return {
								side1: {
									namePlayer: matchSide1?.last_name ? `${matchSide1?.last_name} ${matchSide1?.first_name || ''}` : null
								},
								side2: {
									namePlayer: matchSide2?.last_name ? `${matchSide2?.last_name} ${matchSide2?.first_name || ''}` : null
								},
								isFinished: match.is_finished,
								name: group?.name,
								sets: match?.sets,
								doubleBall: false,
								matchUid: match?.uid,
								levelIndex: levelIndex,
								groupIndex: groupIndex,
								noMatch: !matchSide1 || !matchSide2 ? true : false
							}
						} else {
							// парный
							const playerSide1 = group.nodes.find((item) => {
								return match.side1_uid === item.uid && (item.type === NODE_TYPE_IN || item.type === NODE_TYPE_MIDDLE)
							})
							const matchSide1 = doubles.find((item) => {
								return playerSide1?.double_uid === item.uid
							})

							const playerSide2 = group.nodes.find((item) => {
								return match.side2_uid === item.uid && (item.type === NODE_TYPE_IN || item.type === NODE_TYPE_MIDDLE)
							})
							const matchSide2 = doubles.find((item) => {
								return playerSide2?.double_uid === item.uid
							})

							const side1Player1 = matchSide1?.player1
							const side1Player2 = matchSide1?.player1
							const side2Player1 = matchSide2?.player1
							const side2Player2 = matchSide2?.player1

							return {
								side1: {
									namePlayer1: side1Player1?.last_name ? `${side1Player1?.last_name} ${side1Player1?.first_name || ''}` : null,
									namePlayer2: side1Player2?.last_name ? `${side1Player2?.last_name} ${side1Player2?.first_name || ''}` : null,
								},
								side2: {
									namePlayer1: side2Player1?.last_name ? `${side2Player1?.last_name} ${side2Player1?.first_name || ''}` : null,
									namePlayer2: side2Player2?.last_name ? `${side2Player2?.last_name} ${side2Player2?.first_name || ''}` : null,
								},
								isFinished: match.is_finished,
								sets: match?.sets,
								doubleBall: true,
								matchUid: match?.uid,
								name: group?.name,
								levelIndex: levelIndex,
								groupIndex: groupIndex,
								noMatch: playerSide2?.double_uid && playerSide1?.double_uid ? false : true
							}
						}
					})

					return { matches: matchesArray }
				})
			}).flat(2)

			setTableMatches(tableMatchesArray)
		} else {
			return
		}
	}, [initialStage, doubles, players])

	function openMatch(match) {
		if (!authorized || guestCode || match?.noMatch) {
			return
		}

		let updatedMatch = { ...match, uid: match.matchUid }
		updatedMatch = addFirstSet(updatedMatch, options)
		updatedMatch.pathBack = location.pathname

		dispatch(setMatchData(updatedMatch))
		dispatch(setMatchSession(updatedMatch))

		navigate('/t/' + tournamentUid + '/schedule/' + initialStage?.order_number + '/match')
	}

	function dragRowStyle(provided, snapshot) {
		let style = {
			...provided.draggableProps.style
		}
		const borderStyle = '1px solid var(--palette-live-grey-6)'
		const borderRadius = '11px'
		const backgroundColor = 'var(--palette-live-grey-8)'

		if (snapshot.isDragging) {
			style = {
				...style,
				border: borderStyle,
				borderRadius,
				backgroundColor
			}
		}

		return style
	}

	const getDragItemStyle = useCallback((snapshot, tableIndex) => {
		const borderStyle = '1px solid var(--palette-live-grey-10)'
		const noBorderStyle = 'none'

		return {
			borderBottom: snapshot.isDragging ?
				noBorderStyle : tableIndex === dropTableIndex ? borderStyle : '',
			borderTop:
				tableIndex === dropTableIndex
					&& !snapshot.isDragging
					? borderStyle
					: ''
		}
	}, [dropTableIndex])

	const getStyleSetsBlock = useCallback((snapshot, tableIndex) => {
		return {
			...getDragItemStyle(snapshot, tableIndex)
		}
	}, [getDragItemStyle])

	function handleBeforeDragStart(result) {
		if (result.source) {
			const tableIndex = Number(result.source.droppableId.split('-')[1])

			setDropTableIndex(tableIndex)
		}
	}

	function handleDragEnd(result) {
		if (!result.destination) {
			return
		}

		const { destination, source } = result

		const tableIndex = Number(destination.droppableId.split('-')[1])
		const endIndex = destination.index
		const startIndex = source.index

		const table = tableMatches[tableIndex].matches
		const [removed] = table.splice(startIndex, 1)
		table.splice(endIndex, 0, removed)

		const updatedTableMatches = [...tableMatches]
		updatedTableMatches[tableIndex] = { matches: table }
		setTableMatches(updatedTableMatches)
		setDropTableIndex('')
	}

	return (
		<>
			{tableMatches.length > 0 ?
				<>
					{
						tableMatches?.map((tableMatch, tableMatchIndex) => {
							if (tableMatch?.matches.length > 0) {
								return <div key={tableMatchIndex} className="table-matches">
									<div className="match-group__header">
										<p className="match-group__title">
											{generateGroupOrLevelsName(tableMatchIndex)}
										</p>
									</div>

									<DragDropContext
										onBeforeDragStart={handleBeforeDragStart}
										onDragEnd={handleDragEnd}
									>
										<Droppable droppableId={`table-${tableMatchIndex}`}>
											{
												(provided) => (
													<div
														{...provided.droppableProps}
														ref={provided.innerRef}
														className="match-table__container"
													>
														{
															tableMatch.matches.map((match, matchIndex) => {
																return <Draggable
																	key={match.matchUid}
																	draggableId={match.matchUid}
																	index={matchIndex}
																	// isDragDisabled={!authorized || guestCode || !options?.changeSchedule}
																	isDragDisabled={true}
																>
																	{
																		(provided, snapshot) => (
																			<div
																				ref={provided.innerRef}
																				{...provided.draggableProps}
																				{...provided.dragHandleProps}
																				className="match-table__row" key={matchIndex}
																				style={dragRowStyle(provided, snapshot)}
																			>
																				<div
																					className="match-table__cell cell-order"
																					style={
																						getDragItemStyle(snapshot, tableMatchIndex)
																					}
																				>
																					<p className="match-table__cell-order">
																						{matchIndex + 1}
																					</p>

																					<div className="match-table__cell-ball">
																						{
																							!match.isFinished && match.sets.length > 0 &&
																							<Ball className="match-table__cell-ball" />
																						}
																					</div>
																				</div>

																				<div
																					className="match-table__cell"
																					style={
																						getDragItemStyle(snapshot, tableMatchIndex)
																					}
																				>
																					{
																						match.doubleBall === false ?
																							// Одиночный
																							<div
																								className="match-table__cell-names"
																							>
																								{match.side1.namePlayer ?
																									<p>{match.side1.namePlayer}</p>
																									: <p><EmptyParticipant /></p>
																								}
																								{match.side2.namePlayer ?
																									<p>{match.side2.namePlayer}</p>
																									: <p><EmptyParticipant /></p>
																								}
																							</div>
																							:
																							// Парный
																							!match.side1.namePlayer1 && !match?.side1?.namePlayer2 &&
																								!match.side2.namePlayer1 && !match?.side2?.namePlayer2 ?
																								<div className="match-table__cell-names-doubles">
																									<p className="match-table__cell-doubles-container">
																										<EmptyParticipant />
																										<EmptyParticipant />
																									</p>

																									<p className="match-table__cell-doubles-container">
																										<EmptyParticipant />
																										<EmptyParticipant />
																									</p>
																								</div>
																								:
																								<div className="match-table__cell-names-doubles">
																									<p className="match-table__cell-doubles-container">
																										<>
																											{match.side1?.namePlayer1 ?
																												<span className="match-table__cell-doubles-player">
																													{match.side1?.namePlayer1}
																												</span>
																												: <EmptyParticipant /> // на крайний случай
																											}

																											{match.side1?.namePlayer2 ?
																												<span className="match-table__cell-doubles-player">
																													{match.side1?.namePlayer2}
																												</span>
																												: <EmptyParticipant /> // на крайний случай
																											}
																										</>
																									</p>

																									<p className="match-table__cell-doubles-container">
																										<>
																											{match.side2?.namePlayer1 ?
																												<span className="match-table__cell-doubles-player">
																													{match.side2?.namePlayer1}
																												</span>
																												: <EmptyParticipant /> // на крайний случай
																											}

																											{match.side2?.namePlayer2 ?
																												<span className="match-table__cell-doubles-player">
																													{match.side2?.namePlayer2}
																												</span>
																												: <EmptyParticipant /> // на крайний случай
																											}
																										</>
																									</p>
																								</div>
																					}
																				</div>

																				<div
																					className="match-table__cell"
																					style={
																						getStyleSetsBlock(snapshot, tableMatchIndex)
																					}
																				>
																					<div
																						className={`match-table__cell-container ${
																							type === TYPE_DOUBLES ? 'doubles' : ''
																						}`}
																					>
																						<div className="match-table__cell-sets">
																							<div className="match-table__cell-sets-score">
																								{
																									// счет верхнего игрока
																									match?.sets?.length !== 0 ? (
																										match?.sets?.map((tableMatchSets, tableMatchSetsIndex) => {
																											return (
																												<p
																													key={tableMatchSetsIndex}
																													onClick={() => openMatch(match)}
																												>
																													<span>
																														{tableMatchSets.score1}

																														{
																															tableMatchSets.tie_break_score1 ?
																																<sup>
																																	{tableMatchSets.tie_break_score1}
																																</sup>
																																:
																																''
																														}
																													</span>
																												</p>
																											)
																										})
																									)
																										:
																										(
																											(type === TYPE_DOUBLES && match.side1.namePlayer1) || match.side1.namePlayer ?
																												<p onClick={() => openMatch(match)}>
																													<span>
																														0
																													</span>
																												</p>
																												:
																												<p>
																													<span>
																														0
																													</span>
																												</p>
																										)
																								}
																							</div>

																							<div className="match-table__cell-sets-score">
																								{
																									match?.sets?.length !== 0 ? (
																										match.sets?.map((tableMatchSets, tableMatchSetsIndex) => {
																											return (
																												<p
																													key={tableMatchSetsIndex}
																													onClick={() => openMatch(match)}
																												>
																													<span>
																														{tableMatchSets.score2}

																														{
																															tableMatchSets.tie_break_score2 ?
																																<sup>
																																	{tableMatchSets.tie_break_score2}
																																</sup>
																																:
																																''
																														}
																													</span>
																												</p>
																											)
																										})
																									)
																										:
																										(
																											(type === TYPE_DOUBLES && match.side1.namePlayer1) || match.side2.namePlayer ?
																												<p onClick={() => openMatch(match)}>
																													<span>
																														0
																													</span>
																												</p>
																												:
																												<p>
																													<span>
																														0
																													</span>
																												</p>
																										)
																								}
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		)
																	}
																</Draggable>
															})
														}
														{provided.placeholder}
													</div>
												)
											}
										</Droppable>
									</DragDropContext>
								</div>
							} else {
								return (
									<div
										className="match-group__notable"
										key={tableMatchIndex}
									>
										<p className="match-group__notable-group-name">{generateGroupOrLevelsName(tableMatchIndex)}</p>
									</div>
								)
							}
						})
					}
				</>
				:
				<NoGroups />
			}
		</>
	)
}

export default memo(TableMatches)