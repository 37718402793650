import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import { NEW_PLAYER_BY_LIST } from '../../utils/constants'
import { getSessionStorageItem, parseObject, setItemInSessionStorage } from '../../utils/functions'

function getBooleanValue(str) {
	const value = getSessionStorageItem(str)
	return value === 'false' || !value ? false : true
}

const tournamentParticipantsSlice = createSlice({
	name: 'participants',
	initialState: {
		participants: parseObject('participants', getSessionStorageItem) || [],
		optionsOfSingle: {
			uid: '',
			value: ''
		},
		optionsOfDouble: {
			uid: '',
			value1: '',
			value2: '',
			player1: {},
			player2: {}
		},
		changedList: parseObject('userActions', getSessionStorageItem)?.changedList === 'true' ? true : false,
		createNewPlayer: getBooleanValue('createNewPlayer'),
		createdPlayers: parseObject('createdPlayers', getSessionStorageItem) || [],
		addByList: getBooleanValue('addByList'),
		addedPlayersByList: [],
		parsedDoubles: parseObject('parsedDoubles', getSessionStorageItem) || [],
		parsedDoublesInitial: parseObject('parsedDoublesInitial', getSessionStorageItem) || [],
		dndBetweenIsOn: false,
		noSortedParticipants: []
	},
	reducers: {
		setParticipants(state, action) {
			const { participants } = action.payload
			setItemInSessionStorage('participants', JSON.stringify(participants))
			state.participants = participants
		},
		setNoSortedParticipants(state, action) {
			setItemInSessionStorage('noSortedParticipants', JSON.stringify(action.payload))
			state.noSortedParticipants = action.payload
		},
		addDouble(state, action) {
			const { participant } = action.payload
			const array = _.cloneDeep(state.participants)
			const updated = [...array, participant]

			const dndBetwennIsOn = _.cloneDeep(state.dndBetweenIsOn)

			if (dndBetwennIsOn) {
				const parsedoubles = _.cloneDeep(state.parsedDoubles)
				state.parsedDoubles = [...parsedoubles, participant.player1, participant.player2]
			}

			setItemInSessionStorage('participants', JSON.stringify(updated))
			state.participants = updated
		},
		setUserChangedList(state, action) {
			state.changedList = action.payload
			setItemInSessionStorage('userActions', JSON.stringify(action.payload))
		},
		setOptionsOfSingle(state, action) {
			state.optionsOfSingle = action.payload
		},
		setOptionsOfDouble(state, action) {
			state.optionsOfDouble = action.payload
		},
		setCreateNewPlayer(state, action) {
			setItemInSessionStorage('createNewPlayer', action.payload)
			state.createNewPlayer = action.payload
		},
		setAddByList(state, action) {
			setItemInSessionStorage('addByList', action.payload)
			state.addByList = action.payload
		},
		setCreatedPlayers(state, action) {
			setItemInSessionStorage('createdPlayers', JSON.stringify(action.payload))
			state.createdPlayers = action.payload
		},
		setAddedPlayersByList(state, action) {
			state.addedPlayersByList = action.payload
		},
		setParsedDoubles(state, action) {
			setItemInSessionStorage('parsedDoubles', JSON.stringify(action.payload))
			state.parsedDoubles = action.payload
		},
		setParsedDoublesInitial(state, action) {
			setItemInSessionStorage('parsedDoublesInitial', JSON.stringify(action.payload))
			state.parsedDoublesInitial = action.payload
		},
		setDndBetweenIsOn(state, action) {
			state.dndBetweenIsOn = action.payload
		},
	}
})

export const {
	setParticipants,
	addDouble,
	setUserChangedList,
	setOptionsOfSingle,
	setOptionsOfDouble,
	setCreateNewPlayer,
	setAddByList,
	setCreatedPlayers,
	setAddedPlayersByList,
	setParsedDoubles,
	setParsedDoublesInitial,
	setDndBetweenIsOn,
	setNoSortedParticipants
} = tournamentParticipantsSlice.actions
export default tournamentParticipantsSlice.reducer