import { TYPE_SINGLES } from '../../utils/constants'
import { getNewUid } from '../../utils/functions'

function validateNewPLayersList(players, tournamentList, type, communityPlayers) {
	let currentList = [...players]
	const emptyField = 'Заполните поле'
	const isBusy = 'Участник уже есть в турнире'
	let isValid = true
	const foundInCommunity = {}

	for (let i = 0; i < players.length; i++) {
		const player = {...players[i]}

		const needToSkip = skipPlayer(i, players, player, type)

		if (needToSkip) {
			currentList = currentList.filter((item) => item.index !== player.index)
			continue
		}

		if (player.value.trim().length < 1) {
			player.error = emptyField
			isValid = false
			currentList = replacePlayerInArray(currentList, player)
			continue
		}

		const foundPlayers = findPlayerInCommunity(player.value, communityPlayers)

		if (foundPlayers.length > 0) {
			// создаем объект массивов найденых игроков, где ключи это индексы игроков
			foundInCommunity[`${player.index}`] = foundPlayers
			player.inCommunity = true

			const existsInTournament = findPlayerInTournament(foundInCommunity, tournamentList, type, player.index)

			if (existsInTournament.length > 0) {
				player.error = isBusy
				isValid = false
			}
		}

		currentList = replacePlayerInArray(currentList, player)
	}

	return { checkedList: currentList, isValid, foundInCommunity }
}

function replacePlayerInArray(currentList, player) {
	return currentList.map((item) => {
		if (item.index === player.index) {
			return player
		}

		return item
	})
}

function skipPlayer(index, array, input, type) {
	// выбираем какие инпуты пропустить

	if (type === TYPE_SINGLES) {
		if (input.value.length < 1) {
			// если текущий инпут пустой, то пропускаем его
			return true
		}
	} else {
		// если пара пустая, то пропускаем эти инпуты
		const oddIndex = (index + 1) % 2 === 0
		if (!oddIndex && input.value.length < 1) {
			const nextInput = array[index + 1]

			return nextInput && nextInput.value.length < 1
		} else if (oddIndex && input.value.length < 1) {
			const prevInput = array[index - 1]

			return prevInput && prevInput.value.length < 1
		}
	}

	return false
}

function findPlayerInTournament(existInCommunity, tournamentList, type, playerIndex) {
	const foundedPlayers = existInCommunity[`${playerIndex}`]

	return tournamentList.filter(item => foundedPlayers.some(el => {
		if (type === TYPE_SINGLES) {
			return item.uid === el.uid
		} else {
			return item.player1.uid === el.uid || item.player2.uid === el.uid
		}
	}))
}

function getDoubles(players) {
	const currentList = [...players]
	const pairs = []
	let withoutPair = []

	for (let i = 0; i < currentList.length; i += 2) {
		if (i + 1 < players.length) {
			const newPair = {
				uid: getNewUid(),
				player1:players[i],
				player2: players[i + 1]
			}
			pairs.push(newPair)
		} else {
			withoutPair = players[i]
		}
	}

	return { pairs, withoutPair }
}

function findPlayerInCommunity(player, listOfPlayers) {
	const [word1, word2, word3] = player.split(' ')

	return listOfPlayers.filter(item => {
		const lastName = item?.last_name
		const firstName = item?.first_name
		const middleName = item?.middle_name

		if (word1 && !word2 && !word3) {
			return lastName === word1 || firstName === word1 || middleName === word1

		} else if (word1 && word2 && !word3) {
			const itemStr1 = `${lastName}${firstName}`
			const itemStr2 = `${lastName}${middleName}`
			const itemStr3 = `${firstName}${lastName}`
			const itemStr4 = `${firstName}${middleName}`
			const itemStr5 = `${middleName}${firstName}`
			const itemStr6 = `${middleName}${lastName}`

			const searchStr = `${word1}${word2}`

			return itemStr1 === searchStr
				|| itemStr2 === searchStr
				|| itemStr3 === searchStr
				|| itemStr4 === searchStr
				|| itemStr5 === searchStr
				|| itemStr6 === searchStr
		} else {
			const itemStr1 = `${lastName}${firstName}${middleName}`
			const itemStr2 = `${lastName}${middleName}${firstName}`
			const itemStr3 = `${firstName}${lastName}${middleName}`
			const itemStr4 = `${firstName}${middleName}${lastName}`
			const itemStr5 = `${middleName}${firstName}${lastName}`
			const itemStr6 = `${middleName}${lastName}${firstName}`

			const searchStr = `${word1}${word2}${word3}`

			return itemStr1 === searchStr
				|| itemStr2 === searchStr
				|| itemStr3 === searchStr
				|| itemStr4 === searchStr
				|| itemStr5 === searchStr
				|| itemStr6 === searchStr
		}
	})
}

export {
	validateNewPLayersList,
	getDoubles
}
