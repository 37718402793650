import { GAME, POINTS_DIFFERENCE_BETWEEN_DISPUTANTS, POINTS_PERCENTAGE_BETWEEN_DISPUTANTS, SET } from '../../../utils/constants'

const MATCHES_COLUMN = 0
const SETS_COLUMN = 1

function getColumnsWithSameResults(group) {
	const columns = { sets: false, games: false }

	if (!group?.matches.every(match => match.is_finished === true)) {
		return columns
	}

	const results = group.results
	const minNumberOfParticipants = 2

	if (results.length < minNumberOfParticipants || results[0].sort_key.length < 1) {
		// если только 1 частник или сервер не вернул свой подсчет, то результаты не проверяются
		return columns
	}

	const sameMatchesResults = hasSameResults(results, MATCHES_COLUMN)
	const diffrentSetsResults = hasDiffrentResults(sameMatchesResults, SETS_COLUMN)
	const sameSatesResults = hasSameResults(sameMatchesResults, SETS_COLUMN)
	const matchesAndSetsAreSame = setsAreAsMatches(results)

	if (sameMatchesResults.length > 0 && diffrentSetsResults && !matchesAndSetsAreSame) {
		columns.sets = true
	}

	if (sameMatchesResults.length > 0 && (sameSatesResults.length > 0 || matchesAndSetsAreSame)) {
		columns.games = true
	}

	return columns
}

function hasSameResults(results, clm) {
	const undefinedResults = results.filter((obj) => {
		return results.some((item) => {
			return checkItems(item, obj, clm)
		})
	})

	return undefinedResults
}

function checkItems(item1, item2, clm) {
	if (item1.node_uid !== item2.node_uid) { // не проверяем результаты у одной и тойже ноды

		if (item1?.sort_key[clm] === item2?.sort_key[clm]) {
			return true
		} else {
			return false
		}

	} else {
		return false
	}
}

function hasDiffrentResults(results, clm) {
	// если хотя бы 1 сет показывает отличный результат от других, то колонка должна появиться
	return results.some((obj) => {
		const filtredResults = results.filter(item => item.node_uid !== obj.node_uid)

		return filtredResults.every((item) => {
			return item?.sort_key[clm] !== obj?.sort_key[clm]
		})
	})
}

function setsAreAsMatches(results) {
	return !results.some(item => item.sort_key[MATCHES_COLUMN] !== item.sort_key[SETS_COLUMN])
}

function convertInPercent(item, column) {
	const result = item.sort_key

	if (column === SET && result[1] >= 0) {
		return (result[1] * 100).toFixed(1) + '%'
	} else if (column === GAME && result[2] >= 0) {
		return (result[2] * 100).toFixed(1) + '%'
	} else {
		return
	}
}

function includeInDisput(result, group) {
	const results = group.results

	const isIncluded = results.some((item) => {
		return (
			item.node_uid !== result.node_uid
			&& item.sort_key[0] === result.sort_key[0]
		)
	})

	return isIncluded
}

function getSetsOrGamesResult(participant, rankingSystem, column) {
	const betweenDisputanst = rankingSystem === POINTS_DIFFERENCE_BETWEEN_DISPUTANTS || rankingSystem === POINTS_PERCENTAGE_BETWEEN_DISPUTANTS

	if(column === SET) {
		if (rankingSystem && betweenDisputanst) {
			return `${ participant.set_points_won_between_disputants } – ${ participant.set_points_lost_between_disputants }`
		} else {
			return `${ participant.set_points_won } – ${ participant.set_points_lost }`
		}
	} else {
		if (rankingSystem && betweenDisputanst) {
			return `${ participant.game_points_won_between_disputants } – ${ participant.game_points_lost_between_disputants }`
		} else {
			return `${ participant.game_points_won } – ${ participant.game_points_lost }`
		}
	}
}

function getPointsDiffrence(item, column) {
	const result = item.sort_key

	if (column === SET && typeof result[1] === 'number') {
		return result[1] < 0 ? '–' + Math.abs(result[1]): result[1] === 0 ? '0' : '+' + result[1]
	} else if (column === GAME && typeof result[2] === 'number') {
		return result[2] < 0 ? '–' + Math.abs(result[2]): result[2] === 0 ? '0' : '+' + result[2]
	} else {
		return
	}
}

export {
	includeInDisput,
	convertInPercent,
	getColumnsWithSameResults,
	getSetsOrGamesResult,
	getPointsDiffrence
}