import { getLocalStorageItem } from '../utils/functions'
import {
	ACCESS,
	ACCESS_EXPIRES_AT,
	REFRESH,
	REFRESH_EXPIRES_AT,
} from '../utils/constants'

function getAuthStorageData(store) {
	const storageAccess = getLocalStorageItem(ACCESS)
	const storageRefresh = getLocalStorageItem(REFRESH)
	const storageAccessExpiresAt = getLocalStorageItem(ACCESS_EXPIRES_AT)
	const storageRefreshExpiresAt = getLocalStorageItem(REFRESH_EXPIRES_AT)
	const storageAuthorized = getLocalStorageItem('authorized')
	const storageGuestCode = getLocalStorageItem('guestCode')

	return {
		storageAccess,
		storageRefresh,
		storageAccessExpiresAt,
		storageRefreshExpiresAt,
		storageAuthorized,
		storageGuestCode
	}
}

function getOutpuString(text) {
	return '```json\n' + JSON.stringify(text, null, 3) + '\n```'
}

export {
	getAuthStorageData,
	getOutpuString
}