import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { tokenIsNotExpired } from '../../utils/functions'
import { POST } from '../../utils/constants'
import { setTokenData } from '../../utils/functions2'

export async function refreshToken(
	token, api, extraOptions, dispatch, refreshTokenExpiresAt, timeDelta
) {
	const refreshTokeIsAlive = tokenIsNotExpired(refreshTokenExpiresAt, timeDelta)

	if (!refreshTokeIsAlive) {
		return { success: false }
	}

	const refreshQuery = fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		method: POST,
		body: { refresh: token }
	})

	try {
		const refreshResult = await refreshQuery('/token/refresh/', api, extraOptions)

		if (refreshResult?.data) {
			setTokenData(refreshResult?.data, dispatch)
			return { access: token, success: true }
		} else {
			return { success: false }
		}
	} catch (e) {
		console.log('refreshToken catch error', e)
	}
}

export async function getNewAccess(api, extraOptions, token) {
	const refreshQuery = fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		method: POST,
		body: { refresh: token }
	})

	const refreshResult = await refreshQuery('/token/refresh/', api, extraOptions)

	return refreshResult
}