import { useState, useMemo, Fragment } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useSelector, useDispatch } from 'react-redux'

import './DoublesDragDropList.scss'
import { selectAuth, selectParticipants, selectTournament} from '../../redux/selectors'
import { setNoSortedParticipants, setParsedDoubles, setParticipants, setUserChangedList } from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import { ReactComponent as DragIcon } from '../../icons/drag.svg'
import { ReactComponent as EditMan } from '../../icons/edit-person.svg'
import { getOriginalDouble, itemsChangedOrder } from '../../utils/functions2'
import CloneComponentDnd from '../../reusableComponents/CloneComponentDnd/CloneComponentDnd'
import { getNewUid } from '../../utils/functions'
import Popup from '../../reusableComponents/Popup/Popup'

function DoublesDragDropList() {
	const dispatch = useDispatch()
	const { authorized } = useSelector(selectAuth)
	const { parsedDoubles, parsedDoublesInitial } = useSelector(selectParticipants)
	const { doubles } = useSelector(selectTournament)

	const [playerCloneObj, setPlayerCloneObj] = useState({})
	const [doubleIdxToRemove, setDoubleIdxToRemove] = useState(false)

	const doublesNumbers = useMemo(() => {
		return Array.from({ length: parsedDoubles?.length / 2 })
	}, [parsedDoubles])

	const playerClone = useMemo(() => {
		if (!playerCloneObj.source) {
			return null
		}

		const group = document?.querySelector('.doubles-drag-drop-list__container')

		const targetElement = group?.querySelector(
			`[data-rbd-draggable-id='${playerCloneObj?.draggableId}']`
		)

		if (group?.contains(targetElement)) {
			const clone = targetElement?.cloneNode(true)
			clone.style.opacity = 0.3

			return clone
		}
	}, [playerCloneObj.draggableId, playerCloneObj.source])

	function onDragEnd(result) {
		setPlayerCloneObj({})

		if (!authorized || !result.destination) {
			return
		}

		setTimeout(() => {
			// дает время исчезнуть клону и перерендерить без дерганья списка

			const { destination, source } = result

			const updatedList = [...parsedDoubles]

			const startItem = parsedDoubles[source.index]
			updatedList.splice(destination.index, 1, startItem)

			const endItem = parsedDoubles[destination.index]
			updatedList.splice(source.index, 1, endItem)

			const dragIsDone = itemsChangedOrder(updatedList, parsedDoublesInitial)
			dispatch(setUserChangedList(dragIsDone))

			compileDoubles(updatedList)
			dispatch(setParsedDoubles(updatedList))
		}, 1)
	}

	function compileDoubles(parsedDoubles) {
		const compiledDoubles = []

		for (let i = 0; i < parsedDoubles?.length; i += 2) {
			const player = parsedDoubles[i]
			const nextPlayer = parsedDoubles[i + 1]
			const fakeDoubleUid = getNewUid()

			let compiledDouble = {
				uid: fakeDoubleUid,
				player1: player,
				player2: nextPlayer
			}

			const originalDouble = getOriginalDouble(doubles, { player1: player, player2: nextPlayer })

			if (originalDouble) {
				compiledDouble = {...originalDouble}
			}

			compiledDoubles.push(compiledDouble)
		}

		dispatch(setParticipants({ participants: compiledDoubles }))
		dispatch(setNoSortedParticipants(compiledDoubles)) // если после этого будет сортировка, то откатится к этому списку
	}

	function removeParticipantFromList() {
		let updatedParsedDoubles = [...parsedDoubles]

		updatedParsedDoubles = updatedParsedDoubles?.filter((item, index) => 
			index !== doubleIdxToRemove && index !== doubleIdxToRemove - 1
		)

		dispatch(setParsedDoubles(updatedParsedDoubles))
		compileDoubles(updatedParsedDoubles)
		setDoubleIdxToRemove(null)
	}

	function dragItemStyle(provided, snapshot) {
		const styleTransform = provided.draggableProps.style.transform

		let style = {
			...provided.draggableProps.style,
			transform: !snapshot.isDragging ? null : styleTransform,
			height: '44px',
		}

		const backgroundColor = 'var(--palette-live-blue-3)'

		if (snapshot.isDragging) {
			style = {
				...style,
				backgroundColor,
				webkitBoxShadow: '0px 7px 11px -5px rgba(34, 60, 80, 0.2)',
				MozBoxShadow: '0px 7px 11px -5px rgba(34, 60, 80, 0.2)',
				boxShadow: '0px 7px 11px -5px rgba(34, 60, 80, 0.2)',
				border: 'none'
			}
		}

		return style
	}

	return (
		<div className="doubles-drag-drop-list">
			<div className="doubles-drag-drop-list__order" >
				{
					doublesNumbers?.map((_, index) => (
						<div
							onClick={() => setDoubleIdxToRemove(index + 1)}
							className="doubles-drag-drop-list__order-number"
							key={index}
						>
							<p>
								{index + 1}
							</p>

							<EditMan />
						</div>
					))
				}
			</div>

			<DragDropContext
				onDragEnd={onDragEnd}
				onDragUpdate={(result) => setPlayerCloneObj(result)}
				onBeforeDragStart={(result) => setPlayerCloneObj(result)}
			>
				<Droppable droppableId="doubles-drag-drop-list">
					{
						(provided) => (
							<div
								className="doubles-drag-drop-list__container"
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{
									parsedDoubles && parsedDoubles?.map((el, index) =>
										<Fragment
											key={el.uid}
										>
											{
												playerClone && playerClone.getAttribute('data-rbd-draggable-id') === el?.uid &&
												<CloneComponentDnd htmlContent={playerClone.outerHTML} />
											}

											<Draggable
												key={el.uid}
												draggableId={el.uid}
												index={index}
												isDragDisabled={!authorized ? true : false}
											>
												{
													(provided, snapshot) => (
														<div
															className="doubles-drag-drop-list__player"
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={dragItemStyle(provided, snapshot)}
														>

															<p className="doubles-drag-drop-list__player-name">
																{`${el?.last_name} ${el.first_name}`}
															</p>

															{
																authorized &&
																<div
																	className="doubles-drag-drop-list__block"
																>
																	<span className="doubles-drag-drop-list__player-strength">
																		{el?.strength || ''}
																	</span>

																	<div className="doubles-drag-drop-list__player-dnd">
																		<DragIcon />
																	</div>
																</div>
															}
														</div>
													)
												}
											</Draggable>
										</Fragment>
									)
								}

								<div style={{ display: 'none' }}>
									{provided.placeholder}
								</div>
							</div>
						)
					}
				</Droppable>
			</DragDropContext>

			{doubleIdxToRemove &&
				<Popup title={'Опции участника'} close onClose={() => setDoubleIdxToRemove(null)}>
					<div className="tournament-participants__options">
						<button onClick={() => removeParticipantFromList()}>Удалить участника</button>
					</div>
				</Popup>
			}
		</div>
	)
}

export default DoublesDragDropList