import { createApi } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { jwtDecode } from 'jwt-decode'

import { tokenIsNotExpired, resetAuthorization } from '../utils/functions'
import {
	AUTH_ERROR_STATUS,
	CONNECTION_ERROR,
	requestsWithoutAuth,
	SERVER_ERROR_STATUS
} from '../utils/constants'
import { isCreateComunityRequest, isExeptedError, setTokenData } from '../utils/functions2'

import { setError } from './errorService/errorSlice'
import { getNewAccess, refreshToken } from './authService/refreshApiSlice'
import { sendLogInTg } from './logService/telegramApi'
import {
	restoreAuth,
	setAccessToken,
	setAccessTokenTimeExpiration,
	setTimeDelta,
} from './authService/authSlice'
import { getAuthStorageData, getOutpuString } from './apiExternalFunctions'
import { basicRequest } from './api/basicApi'

const mutex = new Mutex()

const baseQueryWithReauth = async (args, api, extraOptions) => {
	await mutex.waitForUnlock()

	const { dispatch, getState } = api
	const state = getState()
	const { guestCode, timeDelta } = state.auth
	const authState = state.auth
	let {
		authorized,
		refresh,
		access,
		accessExpiresAt,
		refreshTokenExpiresAt
	} = authState
	const { communityUid } = state.community

	const authStorageData = getAuthStorageData(state.auth)
	const reduxCondition = {...authState}
	let tgOutput = { authStorageData, reduxCondition }

	if ((!authorized || !access || !refresh) && !guestCode && !requestsWithoutAuth.includes(args.url) && !isCreateComunityRequest(args)) {
		// проверка авторизации
		if (
			authStorageData.storageAccess
			&& authStorageData.storageRefresh
			&& authStorageData.storageAccessExpiresAt
			&& authStorageData.storageRefreshExpiresAt
		) {
			dispatch(restoreAuth(authStorageData))

			refresh = authStorageData.storageRefresh
			access = authStorageData.storageAccess
			accessExpiresAt = authStorageData.storageAccessExpiresAt
			refreshTokenExpiresAt = authStorageData.storageRefreshExpiresAt
			authorized = true

			await sendLogInTg(api, extraOptions, 'Авторизация была утеряна и восстановлена из storage')
		} else {
			tgOutput = {
				...tgOutput,
				message: 'В редаксе и в storage нет данных об авторизации',
			}

			const resultString = getOutpuString(tgOutput)
			await sendLogInTg(api, extraOptions, resultString)

			resetAuthorization(dispatch, communityUid, 'no_token/s')
			return { error: AUTH_ERROR_STATUS }
		}
	}

	const usedCondition = {
		refresh: JSON.stringify(refresh),
		access: JSON.stringify(access)
	}
	const currentTime = new Date().getTime()

	tgOutput = {
		...tgOutput,
		usedCondition,
		currentTime
	}

	if (authorized && !tokenIsNotExpired(accessExpiresAt, timeDelta) && !guestCode) {
		// если access просрочен
		if (!mutex.isLocked()) {
			const release = await mutex.acquire()

			try {
				const response = await refreshToken(
					refresh, api, extraOptions, dispatch, refreshTokenExpiresAt, timeDelta
				)

				if (!response.success) {
					tgOutput= {
						...tgOutput,
						message: 'Refresh token истек, сброс авторизации'
					}

					const resultString = getOutpuString(tgOutput)
					await sendLogInTg(api, extraOptions, resultString)

					resetAuthorization(dispatch, communityUid, 'refresh_token_time_expired')
					return { error: AUTH_ERROR_STATUS }
				} else {
					access = response.access
				}
			} finally {
				release()
			}
		} else {
			await mutex.waitForUnlock()
		}
	}

	try {
		let result = await basicRequest(args, api, extraOptions, access, guestCode)

		if (result && result?.error) {
			const error = result?.error || {}
			const { status, originalStatus, data = {} } = error

			console.log('result error', result)

			const isException = isExeptedError(data)

			tgOutput = {
				...tgOutput,
				bearer: result?.meta?.request?.headers?.get('Authorization'),
				xAuthCode: result?.meta?.request?.headers?.get('X-Auth-Code'),
				apiError: {
					url: result?.meta?.request?.url,
					method: result?.meta?.request?.method,
					body: args?.body ? JSON.stringify(args?.body, null, 3) : null,
					error: result?.error,
				}
			}

			if (
				(status !== AUTH_ERROR_STATUS || originalStatus === SERVER_ERROR_STATUS || status === CONNECTION_ERROR)
				&& !isException
			) {
				const resultString = getOutpuString(tgOutput)

				dispatch(setError(error))
				await sendLogInTg(api, extraOptions, resultString)
			} else if (status === AUTH_ERROR_STATUS && authorized) {
				// если все таки сервер отдал 401
				tgOutput = {
					...tgOutput,
					message: 'Сервер не принял token. Обновляем access',
				}

				const resultString = getOutpuString(tgOutput)
				await sendLogInTg(api, extraOptions, resultString)

				if (!mutex.isLocked()) {
					const release = await mutex.acquire()

					try {
						const accessResult = await getNewAccess(api, extraOptions, refresh)

						if (accessResult?.data?.access) {
							setTokenData(accessResult?.data, dispatch)

							result = await basicRequest(args, api, extraOptions, accessResult?.data?.access, guestCode)

							await sendLogInTg(api, extraOptions, 'Сервер принял access')
						} else {
							tgOutput = {
								...tgOutput,
								message: 'Не удалось сделать дополнительное обновление токена. Сброс авторизации',
								apiError: accessResult?.error
							}

							const resultString = getOutpuString(tgOutput)

							await sendLogInTg(api, extraOptions, resultString)
							resetAuthorization(dispatch, communityUid, 'refresh_token_invalid')
							return
						}
					} finally {
						release()
					}
				} else {
					await mutex.waitForUnlock()
				}
			}
		}

		console.log(result)

		return result
	} catch (e) {
		const resultString = getOutpuString({ ...tgOutput, args, err: e })

		await sendLogInTg(api, extraOptions, resultString)
		console.log('basic api catch error', e)
	}
}

export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({})
})