import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'

import './RoundRobinGrid.scss'
import { generateGroupOrLevelsName, getParameters } from '../../../utils/functions'
import { selectAuth, selectTournament, selectTournamentOptions } from '../../../redux/selectors'
import { setMatchData, setMatchSession } from '../../../redux/matchService/matchSlice'
import { TYPE_SINGLES } from '../../../utils/constants'
import RoundRobinFull from '../RoundRobinFull/RoundRobinFull'
import RoundRobinShort from '../RoundRobinShort/RoundRobinShort'
import { setResultModalList } from '../../../redux/roundRobinService/roundRobinSlice'
import { addFirstSet } from '../../../views/Match/externalFunctions'
import { processGroup } from '../../../utils/functions2'

function RoundRobinGrid({ group }) {
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useDispatch()
	const { tournamentParams, stageNumber } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const { authorized, guestCode } = useSelector(selectAuth)
	const { type, players, doubles } = useSelector(selectTournament)
	const { stage: options = {} } = useSelector(selectTournamentOptions)
	// const { showResultEditorModal } = useSelector(selectRoundRobin)

	// Получаем объединенный массив для таблицы Сетка
	const [grid, setGrid] = useState([])
	const [gridResults, setGridResults] = useState([])

	function processGroupResults(group) {
		return group.results.map(result => result)
	}

	useEffect(() => {
		const gridArr = processGroup(group, players, doubles)
		const gridResultArr = processGroupResults(group)

		setGrid(gridArr)
		dispatch(setResultModalList(gridArr))
		setGridResults(gridResultArr)
	}, [doubles, players, group])

	// Возвращаем матчи и инвертируем их по необходимости
	function getMatchPlusInverted(indexOne, indexTwo) {
		const matches = group.matches
		let inverted = Boolean
		let foundMatch = []
		const sideOneUid = grid[indexOne]?.nodeUid || grid[indexOne]?.uid
		const sideTwoUid = grid[indexTwo]?.nodeUid || grid[indexTwo]?.uid

		foundMatch = matches.filter(match =>
			match.side1_uid === sideOneUid && match.side2_uid === sideTwoUid
		)

		if (foundMatch.length === 0) {
			// Если ничего не нашли, то мы проверям повторно, но инвертировав index1 и index2
			foundMatch = matches.filter(match =>
				match.side2_uid === sideOneUid && match.side1_uid === sideTwoUid
			)

			if (foundMatch.length !== 0) {
				// Если мы инвертировав нашли нужный нам элемент, то указываем в inverted = true
				inverted = true
			} else {
				// Если не смогли получить матч игроков, то возвращаем пустой массив
				inverted = false

				return [
					[],
					inverted
				]
			}
		} else {
			// Если нашли, то указываем в inverted = false
			inverted = false
		}

		return [
			foundMatch,
			inverted
		]
	}

	function defineAvailablityOfPlayer(player) {
		if (type === TYPE_SINGLES) {
			return {
				...player,
				available: player?.formedName ? true : false
			}
		} else {
			return {
				...player,
				available: player?.player1 ? true : false
			}
		}
	}

	// Вызываем внутри таблицы для получения матчей
	function getMatch(playerOne, playerTwo, indexOne, indexTwo) {
		const updatedPlayerOne = defineAvailablityOfPlayer(playerOne)
		const updatedPlayerTwo = defineAvailablityOfPlayer(playerTwo)

		const [matches, inverted] = getMatchPlusInverted(indexOne, indexTwo)
		return setString(matches, inverted, updatedPlayerOne, updatedPlayerTwo)
	}

	function formMatch(match, participantOne, participantTwo, inverted) {
		let matchWithPlayers = addFirstSet(match[0], options)

		const invertedMatch = {
			...matchWithPlayers,
			sets: matchWithPlayers.sets.map(set => {
				return {
					...set,
					score1: inverted ? set.score2 : set.score1,
					score2: inverted ? set.score1 : set.score2,
					tie_break_score1: inverted ? set.tie_break_score2 : set.tie_break_score1,
					tie_break_score2: inverted ? set.tie_break_score1 : set.tie_break_score2,
				}
			})
		}

		if (type === TYPE_SINGLES) {
			matchWithPlayers = {
				...invertedMatch,
				side1: {
					namePlayer: participantOne?.formedName,
					nodeUid: inverted ? participantTwo.nodeUid : participantOne.nodeUid
				},
				side2: {
					namePlayer: participantTwo?.formedName,
					nodeUid: inverted ? participantOne.nodeUid : participantTwo.nodeUid
				}
			}
		} else {
			matchWithPlayers = {
				...invertedMatch,
				side1: {
					namePlayer1: participantOne?.player1?.formedName,
					namePlayer2: participantOne?.player2?.formedName,
					nodeUid: inverted ? participantTwo.nodeUid : participantOne.nodeUid
				},
				side2: {
					namePlayer1: participantTwo?.player1?.formedName,
					namePlayer2: participantTwo?.player2?.formedName,
					nodeUid: inverted ? participantOne.nodeUid : participantTwo.nodeUid
				}
			}
		}

		return {
			...matchWithPlayers,
			inverted
		}
	}

	function openMatch(match, playerOne, playerTwo, inverted) {
		console.log()
		if (!authorized || guestCode) {
			return
		}

		if (!playerOne?.available || !playerTwo?.available) {
			return
		}

		const matchWithPlayers = formMatch(match, playerOne, playerTwo, inverted)
		matchWithPlayers.pathBack = location.pathname
		dispatch(setMatchData(matchWithPlayers))
		dispatch(setMatchSession(matchWithPlayers))
		navigate('/t/' + tournamentUid + '/draws/' + stageNumber + '/match')
	}

	// Формируем данные для таблицы
	function setString(match, inverted, playerOne, playerTwo) {
		// Получаем все сеты у всех матчей
		const sets = match[0]?.sets || []
		const cursor = 'pointer'

		// console.log(sets, group.order_number)

		// Проверяем, если сетов нет
		if (sets.length === 0) {
			return (
				<div
					className="roundrobin-grid__cell-score"
					style={{ cursor: authorized && !guestCode ? cursor : '' }}
					onClick={() => openMatch(match, playerOne, playerTwo, inverted)}
				>
					<p>
						<span></span>
					</p>
				</div>
			)
		} else {
			// Если сеты есть
			return (
				<div
					className="roundrobin-grid__cell-score"
					style={{ cursor: authorized ? cursor : '' }}
					onClick={() => openMatch(match, playerOne, playerTwo, inverted)}
				>
					{
						sets.map((set, setIndex) => {
							// 0 в тайбрейке считается как false, сравниваем с null
							const tieBreakScore1 = set.tie_break_score1 !== null ? set.tie_break_score1.toString() : ''
							const tieBreakScore2 = set.tie_break_score2 !== null ? set.tie_break_score2.toString() : ''

							if (inverted) {
								return (
									<p key={setIndex}>
										<span>
											{set.score2}
											<sup>
												{tieBreakScore2}
											</sup>
											:
											{set.score1}
											<sup>
												{tieBreakScore1}
											</sup>
										</span>
									</p>
								)
							} else {
								return (
									<p key={setIndex}>
										<span>
											{set.score1}
											<sup>
												{tieBreakScore1}
											</sup>
											:
											{set.score2}
											<sup>
												{tieBreakScore2}
											</sup>
										</span>
									</p>
								)
							}
						})
					}
				</div>
			)
		}
	}

	function checkMatch(playerOne, playerTwo) {
		const match = group?.matches.find(
			match => (match.side1_uid === playerOne?.nodeUid && match.side2_uid === playerTwo?.nodeUid) ||
				(match.side2_uid === playerOne?.nodeUid && match.side1_uid === playerTwo?.nodeUid)
		)

		return !match?.is_finished && match?.sets.length > 0
	}

	return (
		<>
			{grid.length > 2 ?
				<RoundRobinFull
					grid={grid}
					group={group}
					gridResults={gridResults}
					getMatch={getMatch}
					checkMatch={checkMatch}
				/>
				: grid.length > 0 ?
					<RoundRobinShort
						grid={grid}
						group={group}
						gridResults={gridResults}
						openMatch={openMatch}
						checkMatch={checkMatch}
					/>
					:
					<div
						className="round-robin__nogrid"
					>
						<p className="round-robin__nogrid-group-name">{generateGroupOrLevelsName(group.order_number - 1)}</p>
					</div>
			}

			{/* {
				showResultEditorModal && auth &&
				<RoundRobinResultModal />
			} */}
		</>
	)
}

export default RoundRobinGrid