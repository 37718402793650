import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import './RoundRobinFull.scss'
import { generateGroupOrLevelsName } from '../../../utils/functions'
import { selectAuth, selectRoundRobin, selectTournament } from '../../../redux/selectors'
import { GAME, POINTS_DIFFERENCE_BETWEEN_DISPUTANTS, POINTS_PERCENTAGE, POINTS_PERCENTAGE_BETWEEN_DISPUTANTS, SET, TYPE_DOUBLES } from '../../../utils/constants'
import { ReactComponent as Ball } from '../../../icons/tennis_ball.svg'
import Tooltip from '../../../reusableComponents/Tooltip/Tooltip'
import StageGroupIcon from '../../../reusableComponents/StageGroupIcon/StageGroupIcon'
import { setShowExtraColumns } from '../../../redux/roundRobinService/roundRobinSlice'

import { getColumnsWithSameResults, convertInPercent, includeInDisput, getSetsOrGamesResult, getPointsDiffrence } from './externalFunctions'
function RoundRobinFull({
	grid,
	group,
	gridResults,
	getMatch,
	checkMatch
}) {
	const dispatch = useDispatch()
	const { type, round_robin_ranking_system } = useSelector(selectTournament)
	const { authorized, guestCode } = useSelector(selectAuth)
	const { showExtraColumns } = useSelector(selectRoundRobin)

	const showColumn = useMemo(() => {
		return getColumnsWithSameResults(group, round_robin_ranking_system)
	}, [group, round_robin_ranking_system])

	const rrSystemBetweenDisputants = useMemo(() => {
		return round_robin_ranking_system === POINTS_PERCENTAGE_BETWEEN_DISPUTANTS || round_robin_ranking_system === POINTS_DIFFERENCE_BETWEEN_DISPUTANTS
	}, [round_robin_ranking_system])

	const needPercent = useMemo(() => {
		return round_robin_ranking_system === POINTS_PERCENTAGE_BETWEEN_DISPUTANTS || round_robin_ranking_system === POINTS_PERCENTAGE
	}, [round_robin_ranking_system])

	return (
		<>
			<div className="roundrobin-grid__wrapper">
				<div className="roundrobin-grid__sheet">
					<div className="roundrobin-grid__content--sticky">
						<div className="roundrobin-grid__row row-header">
							<div className="roundrobin-grid__header group-name__container">
								<div className="group-name">
									{generateGroupOrLevelsName(group.order_number - 1)}

									<StageGroupIcon type={group?.type} styleProp={{
										fill: 'var(--palette-live-grey-0)',
										height: '18.25px'
									}} />
								</div>
							</div>
						</div>

						{
							grid?.map((itemVer, indexVertical) => {
								return (
									<div
										className={`roundrobin-grid__row ${type === TYPE_DOUBLES ? 'row-doubles' : ''}`}
										key={indexVertical}
									>
										{
											itemVer.player1 ?
												// Парный
												<div className="roundrobin-grid__cell participant-name__container">
													<div className="participant-name__order">
														{indexVertical + 1}
													</div>

													{
														itemVer.player1?.formedName ?
															<div className="participant-name__item">
																<Tooltip
																	content={`${itemVer?.player1.last_name} ${itemVer?.player1.first_name || ''} ${itemVer?.player1.middle_name || ''}`}
																	disable={itemVer?.player1?.formedName ? false : true}
																>
																	<span>
																		{itemVer.player1?.formedName}
																	</span>
																</Tooltip>

																<Tooltip
																	content={`${itemVer?.player2.last_name} ${itemVer?.player2.first_name || ''} ${itemVer?.player2.middle_name || ''}`}
																	disable={itemVer?.player2?.formedName ? false : true}
																>
																	<span>
																		{itemVer.player2?.formedName}
																	</span>
																</Tooltip>
															</div>
															:
															<div className="participant-name__item">
																{itemVer.name}
															</div>
													}
												</div>
												:
												// Одиночный
												<div className="roundrobin-grid__cell participant-name__container">
													<div className="participant-name__order">
														{indexVertical + 1}
													</div>

													<Tooltip
														content={`${itemVer?.last_name} ${itemVer?.first_name || ''} ${itemVer?.middle_name || ''}`}
														disable={itemVer?.formedName ? false : true}
													>
														<div className="participant-name__item">
															{
																itemVer?.formedName ?
																	`${itemVer?.formedName}`
																	:
																	itemVer?.name
															}
														</div>
													</Tooltip>
												</div>
										}
									</div>
								)
							})
						}
					</div>

					<div className={`roundrobin-grid__row empty ${type === TYPE_DOUBLES ? 'row-doubles' : ''}`}></div>

					<div className="roundrobin-grid__content">
						<div className="roundrobin-grid__row row-header">
							{
								grid?.map((_, index) => {
									return (
										<div
											className="roundrobin-grid__header"
											key={index}
										>
											<div className="participant-name__order">
												{index + 1}
											</div>
										</div>
									)
								})
							}
						</div>

						{
							grid?.map((itemVer, indexVertical) => {
								return (
									<div
										className={`roundrobin-grid__row ${type === TYPE_DOUBLES ? 'row-doubles' : ''}`}
										key={indexVertical}
									>
										{
											grid?.map((itemHor, indexHorizontally) =>
												<div
													className={
														`roundrobin-grid__cell ${indexVertical === indexHorizontally ?
															'roundrobin-grid__cell--grey'
															:
															''
														}`
													}
													key={indexHorizontally}
												>
													{
														indexVertical === indexHorizontally ?
															''
															:
															<div className="roundrobin-grid__cell-sets">
																{getMatch(itemVer, itemHor, indexVertical, indexHorizontally)}
															</div>
													}

													{
														checkMatch(itemVer, itemHor) &&
														<Ball className="roundrobin-grid__icon-ball" />
													}
												</div>
											)
										}
									</div>
								)
							})
						}
					</div>

					<div className={`roundrobin-grid__row empty ${type === TYPE_DOUBLES ? 'row-doubles' : ''}`}></div>

					<div className="roundrobin-grid__content">
						<div className="roundrobin-grid__row row-header">
							<div
								className={`roundrobin-grid__header blue ${authorized && !guestCode ? 'active' : ''}`}
								onClick={authorized && !guestCode ? () => dispatch(setShowExtraColumns(!showExtraColumns)) : undefined}
							>
								M
							</div>

							{
								showExtraColumns &&
								<div className="roundrobin-grid__header">
									S
								</div>
							}

							{
								showExtraColumns &&
								<div className="roundrobin-grid__header">
									G
								</div>
							}

							{
								((!rrSystemBetweenDisputants && !showExtraColumns && showColumn.sets)
								|| (rrSystemBetweenDisputants && (showColumn.sets || showExtraColumns))) &&
								<div className="roundrobin-grid__header">
									S

									{
										rrSystemBetweenDisputants &&
										<span className="roundrobin-grid__header--inner">
											Между спорящими
										</span>
									}
								</div>
							}

							{
								((!rrSystemBetweenDisputants && !showExtraColumns && showColumn.games)
								|| (rrSystemBetweenDisputants && (showColumn.games || showExtraColumns))) &&
								<div className="roundrobin-grid__header">
									G

									{
										rrSystemBetweenDisputants &&
										<span className="roundrobin-grid__header--inner">
											Между спорящими
										</span>
									}
								</div>
							}

							<div
								// onClick={() => dispatch(setShowResultEditorModal(true))}
								className="roundrobin-grid__header result-cell"
							>
								R
							</div>
						</div>

						{
							grid?.map((_, indexVertical) => {
								return (
									<div
										className={`roundrobin-grid__row ${type === TYPE_DOUBLES ? 'row-doubles' : ''}`}
										key={indexVertical}
									>
										<div className="roundrobin-grid__cell result-cell__score">
											<span className="roundrobin-grid__cell-match-result">
												{!group?.matches.every(match => match.is_finished === true) ? '' : `${gridResults[indexVertical]?.match_points_won} – ${gridResults[indexVertical]?.match_points_lost}`}
											</span>
										</div>

										{
											showExtraColumns &&
											<div className="roundrobin-grid__cell">
												<div className="roundrobin-grid__cell-container result-cell__score">
													<span className="roundrobin-grid__cell--inner">
														{getSetsOrGamesResult(gridResults[indexVertical], null, SET)}
													</span>

													<span className="roundrobin-grid__cell--inner">
														{needPercent ?
															convertInPercent(gridResults[indexVertical], SET, group)
															:
															getPointsDiffrence(gridResults[indexVertical], SET)
														}
													</span>
												</div>
											</div>
										}

										{
											showExtraColumns &&
											<div className="roundrobin-grid__cell">
												<div className="roundrobin-grid__cell-container result-cell__score">
													<div className="roundrobin-grid__cell--inner">
														{getSetsOrGamesResult(gridResults[indexVertical], null, GAME)}
													</div>

													<div className="roundrobin-grid__cell--inner">
														{needPercent ?
															convertInPercent(gridResults[indexVertical], GAME, group)
															:
															getPointsDiffrence(gridResults[indexVertical], GAME)
														}
													</div>
												</div>
											</div>
										}

										{
											((!rrSystemBetweenDisputants && !showExtraColumns && showColumn.sets)
											|| (rrSystemBetweenDisputants && (showColumn.sets || showExtraColumns))) &&
											<div className="roundrobin-grid__cell">
												{
													includeInDisput(gridResults[indexVertical], group) ?
														<div className="roundrobin-grid__cell-container result-cell__score">
															<span className="roundrobin-grid__cell--inner">
																{getSetsOrGamesResult(gridResults[indexVertical], round_robin_ranking_system, SET)}
															</span>

															<span className="roundrobin-grid__cell--inner">
																{needPercent ?
																	convertInPercent(gridResults[indexVertical], SET, group)
																	:
																	getPointsDiffrence(gridResults[indexVertical], SET)
																}
															</span>
														</div>
														:
														<div className="roundrobin-grid__cell-container">
															<span className="roundrobin-grid__cell--inner"></span>
															<span className="roundrobin-grid__cell--inner"></span>
														</div>
												}
											</div>
										}

										{
											((!rrSystemBetweenDisputants && !showExtraColumns && showColumn.games)
											|| (rrSystemBetweenDisputants && (showColumn.games || showExtraColumns))) &&
											<div className="roundrobin-grid__cell">
												<div className="roundrobin-grid__cell-container result-cell__score">
													<div className="roundrobin-grid__cell--inner">
														{getSetsOrGamesResult(gridResults[indexVertical], round_robin_ranking_system, GAME)}
													</div>

													<div className="roundrobin-grid__cell--inner">
														{needPercent ?
															convertInPercent(gridResults[indexVertical], GAME, group)
															:
															getPointsDiffrence(gridResults[indexVertical], GAME)
														}
													</div>
												</div>
											</div>
										}

										<div
											className="roundrobin-grid__cell result-cell result-cell__score"
										// onClick={() => setOpenResultEditor(true)}
										>
											{gridResults[indexVertical]?.ranking}
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default RoundRobinFull