import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import './Tournaments.scss'
import { useGetCommunityQuery } from '../../redux/communityService/communityApiSlice'
import Header from '../../reusableComponents/Header/Header'
import TournamentsList from '../../extendedComponents/TournamentsList/TournamentsList'
import AddButton from '../../reusableComponents/AddButton/AddButton'
import { ReactComponent as RocketsIcon } from '../../icons/rokets.svg'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import Plug from '../../reusableComponents/Plug/Plug'
import Loader from '../../reusableComponents/Loader/Loader'

import { selectCommunity, selectTournaments } from '../../redux/selectors'
import {
	setCurrentStageNumber,
	setImmutableProcessedLevels,
	setInitialStage,
	setPlayersList,
	setProcessedLevelsForBuildMode,
	setProcessedStage,
	setShowPrestagerating
} from '../../redux/stageService/stageSlice'
import { setTournamentData } from '../../redux/singleTournamentService/singleTournamentSlice'
import { setCommunityPlayers, setInitPlayers } from '../../redux/playersService/playersSlice'
import { setNoSortedParticipants, setParticipants } from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import { FULFILLED } from '../../utils/constants'
import { setTournamentsScroll } from '../../redux/tournamentsService/tournamentsSlice'

function Tournaments() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { communityUid, communityName } = useSelector(selectCommunity)
	const { scroll } = useSelector(selectTournaments)
	const [tournaments, setTournaments] = useState([])

	const { data = {}, status, error } = useGetCommunityQuery(
		{ community_uid: communityUid }, { skip: !communityUid, refetchOnMountOrArgChange: true }
	)

	useEffect(() => {
		dispatch(setCurrentStageNumber(1))
		dispatch(setTournamentData({}))
		dispatch(setProcessedStage({}))
		dispatch(setInitialStage({}))
		dispatch(setProcessedLevelsForBuildMode([]))
		dispatch(setImmutableProcessedLevels([]))
		dispatch(setPlayersList([]))
		dispatch(setShowPrestagerating(null))
		dispatch(setNoSortedParticipants([]))
		dispatch(setParticipants([]))
		dispatch(setCommunityPlayers([]))
		dispatch(setInitPlayers([]))
	}, [])

	useEffect(() => {
		if (scroll) {
			window.scrollTo({
				behavior: 'smooth',
				left: 0,
				top: 1000,
			})
			dispatch(setTournamentsScroll(null))
			sessionStorage.clear()
		}
	}, [scroll, dispatch])

	useEffect(() => {
		if (Object.keys(data).length !== 0) {
			setTournaments(data?.tournaments)
		}
	}, [data, dispatch])

	if (error) { console.log(error) }

	return (
		<>
			<Header
				title={t('Tournaments')}
				communityName={communityName}
				border
			/>

			<main className="tournaments" onClick={() => { window.scrollTo(0, 0) }}>
				{
					status !== FULFILLED ?
						<Loader />
						:
						<>
							{
								tournaments?.length < 1 ?
									<Plug
										icon={<RocketsIcon className="icon" />}
										title={t('Create tournament')}
										description={t('Click on the plus for creating a tournament')}
									/>
									:
									<TournamentsList
										list={tournaments}
										categories={data.categories}
										venues={data.venues}
										managers={data.managers}
									/>
							}

							<AddButton
								to={'/form/'}
							/>
						</>
				}
			</main>

			<BottomMenu />
		</>
	)
}

export default Tournaments