import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './TournamentSchedule.scss'
import { useGetStageQuery } from '../../redux/stageService/stageApiSlice'
import { selectAuth, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import { useProcessStageData } from '../../hooks/stageHooks'
import TableMatches from '../../extendedComponents/TableMatches/TableMatches'
import NoContent from '../../reusableComponents/NoContent/NoContent'
import CombineTournamentMenu from '../../reusableComponents/CombineTournamentMenu/CombineTournamentMenu'
import HeaderTournament from '../../reusableComponents/HeaderTournament/HeaderTournament'
import Loader from '../../reusableComponents/Loader/Loader'
import { getParameters } from '../../utils/functions'
import { FULFILLED } from '../../utils/constants'
import StageGeneration from '../../extendedComponents/Stage/StageGeneration/StageGeneration'
import RemoveStageButton from '../../reusableComponents/RemoveStageButton/RemoveStageButton'
import { isNumeric } from '../../utils/functions2'

function TournamentSchedule({ category, tournamentStartDate, tournamentRefetch }) {
	const { tournamentParams, stageNumber } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const { stages_count, name, players, doubles } = useSelector(selectTournament)
	const { authorized, guestCode } = useSelector(selectAuth)
	const { stage: options = {} } = useSelector(selectTournamentOptions)

	const participants = players || doubles

	const { data: stageData = {}, status } = useGetStageQuery({
		tournament_uid: tournamentUid,
		stageNumber
	}, {
		skip: !stages_count || stages_count < 1
			|| stageNumber < 1 || stageNumber > stages_count || !isNumeric(stageNumber),
		refetchOnMountOrArgChange: stages_count > 0
	})

	useProcessStageData(stageData)

	// можно ли показать сетки. name проверяется, чтобы убедиться, что турнир уже загрузился
	const firstCondition = (name && stages_count > 0 && status === FULFILLED && stageData?.levels?.length > 0)

	// показ NoContent для режима, у которого есть вкладка Структура
	const conditionForWideRegims = options?.modes?.tabsAreVisible && (
		(stages_count > 0 && status === FULFILLED && stageData?.levels?.length < 1) || stages_count < 1 || stageNumber > stages_count
	)

	// показ NoContent для режима, у которого нет вкладки Структура
	const conditionForLimitedRegim = participants?.length < 1 && !options?.modes?.tabsAreVisible

	const secondCondition = (name && options?.modes && (
		conditionForWideRegims || conditionForLimitedRegim)
	) || !authorized || guestCode || stageNumber < 1

	// показ Автогенерации для режима, у которого нет вкладки Структура
	const thirdCondition = name && participants && participants.length > 0
	&& options?.modes && !options?.modes?.tabsAreVisible && (
		(stages_count < 1 && stageNumber < 2) || (stages_count > 1 && stageNumber > 1 && stageData?.levels?.length < 1)
	)  && authorized && !guestCode

	// показ меню этапов
	const forthCondition = name && stages_count > 0 && authorized && options?.modes
		&& !options?.modes?.tabsAreVisible && !guestCode && stageNumber < stages_count && stageNumber > 0

	return (
		<>
			<HeaderTournament
				date={tournamentStartDate}
				category={category}
				copyLink={authorized && !guestCode ? true : false}
				background
			/>

			<main className="tournament-schedule__container">
				<div className="tournament-schedule">
					{
						firstCondition ?
							<TableMatches />
							: secondCondition ?
								<NoContent
									table={'Расписания'}
									noStage={
										(Number(stageNumber) !== 1 && stages_count === 0)
										|| (stages_count > 0 && Number(stageNumber) > stages_count)
										|| stageNumber < 1
									}
								/>
								: thirdCondition ?
									<StageGeneration
										tournamentRefetch={tournamentRefetch}
									/>
									:
									<Loader />
					}
				</div>

				{forthCondition &&
					<RemoveStageButton tournamentRefetch={tournamentRefetch} />
				}
			</main>

			<CombineTournamentMenu />
		</>
	)
}

export default memo(TournamentSchedule)