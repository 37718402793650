import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { POST } from '../../utils/constants'

export async function sendLogInTg(api, extraOptions, message) {
	const tgLogQuery = fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		method: POST,
		body: { message }
	})

	try {
		await tgLogQuery('/log/', api, extraOptions)
	} catch (e) {
		console.log('sendLogInTg catch error', e)
	}
}