import { createSlice } from '@reduxjs/toolkit'

const roundRobinSlice = createSlice({
	name: 'roundRobin',
	initialState: {
		roundRobinGroupList: [],
		showResultEditorModal: false,
		showExtraColumns: false
	},
	reducers: {
		setResultModalList(state, action) {
			state.roundRobinGroupList = action.payload
		},
		setShowResultEditorModal(state, action) {
			state.showResultEditorModal = action.payload
		},
		setShowExtraColumns(state, action) {
			state.showExtraColumns = action.payload
		}
	}
})

export const {
	setResultModalList,
	setShowResultEditorModal,
	setShowExtraColumns
} = roundRobinSlice.actions
export default roundRobinSlice.reducer